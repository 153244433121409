import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';

const ContactInfo = ({ formData, setFormData, setIsStepValid }) => {
  const { t } = useTranslation();
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    setIsStepValid(true);
    setFormData((prev) => ({
      ...prev,
      contact_phone: prev.contact_phone || '',
    }));
  }, [setIsStepValid, setFormData]);

  const handlePhoneChange = (value) => {
    
    if (!value) {
      setPhoneError('Phone number is required'); 
      return;
    }

    const parsedPhoneNumber = parsePhoneNumber(value) || {};

    const countryCallingCode = _.get(parsedPhoneNumber, 'countryCallingCode', '');

    setFormData((prev) => ({
      ...prev,
      contact_phone: value,
      contact_country_code: countryCallingCode || '',
    }));

    if (!_.isEmpty(phoneError)) {
      setPhoneError('');
    }
  };

  return (
    <div className="space-y-4">
      <h5 className="text-xl font-semibold mb-4">{t('addStationForm.owner/contactPerson')}</h5>
      <Row>
        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.name')}
            name="contact_name"
            value={formData.contact_name || ''}
            onChange={(e) => setFormData({ ...formData, contact_name: e.target.value })}
          />
        </Col>

        <Col lg={6} md={12}>
          <PhoneNumberInput
            label={t('addStationForm.phoneNumber')}
            name="contact_phone"
            value={formData.contact_phone ? `+91${formData.contact_phone}` : ''}
            // value={]formData.contact_phone || ''}
            onChange={handlePhoneChange}
            error={phoneError}
          />
        </Col>

        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.emailAddress')}
            name="contact_email"
            value={formData.contact_email || ''}
            onChange={(e) => setFormData({ ...formData, contact_email: e.target.value })}
          />
        </Col>

        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.officeAddress')}
            name="contact_address"
            value={formData.contact_address || ''}
            onChange={(e) => setFormData({ ...formData, contact_address: e.target.value })}
          />
        </Col>
      </Row>
    </div>
  );
};

ContactInfo.propTypes = {
  formData: PropTypes.shape({
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    contact_email: PropTypes.string,
    contact_address: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsStepValid: PropTypes.func.isRequired,
};

export default ContactInfo;