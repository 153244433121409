import {
  ADMIN,
  SUPER_ADMIN,
  TENANT_ADMIN,
  GENTARI_CUSTOM_ROLE,
  B2BCLIENT,
  FINANCE,
  DOCO,
  CPO,
  SUPPORT,
  CUSTOMER,
  GCM_CPO,
  MNM_DEALER,
  SAAS_ADMIN,
  MD_ADMIN,
  MD_DEALER,
  SUPPORT_ADMIN,
  NETWORK_ADMIN,
  FLEET_OWNER,
  FLEET_MEMBER,
  GET_CHARGING_STATIONS,
  GET_REPORT_TRANSACTION,
  GET_REPORT_CHARGING_SESSION,
  MANAGE_CHARGING_STATIONS,
  GET_CHARGERS,
  MANAGE_CHARGERS,
  GET_USER_GROUPS,
  MANAGE_USER_GROUPS,
  GET_VEHICLES,
  MANAGE_VEHICLES,
  GET_NOTIFICATIONS,
  MANAGE_OFFERS,
  GET_OFFERS,
  GET_RATING,
  GET_TENANAT_SETTINGS,
  GET_OCPI,
  MANAGE_OCPI,
  GET_CHARGER_LOGS,
  GET_CUSTOMERS,
  GET_FLEET_MEMBERS,
  MANAGE_FLEET_MEMBERS,
  GET_TARRIFS,
  MANAGE_TARRIFS,
  GET_CHARGER_BOOKINGS,
  GET_MY_BOOKINGS,
  GET_WALLETS,
  GET_CHARGER_DOWNTIMES,
  GET_PAYMENTS,
  GET_MQTT_LOGGER,
  GET_MASTER_SETTINGS,
  MANAGE_USERS,
  GET_RFID_GROUPS,
  GET_RFID_REQUESTS,
  UPDATE_MY_PROFILE,
  GET_CHARGER_STATES,
  STOP_TRANSACTION,
  GET_TAGS,
  GET_FAULTY_BOOKINGS,
  MANAGE_NO_LOAD_REPORT,
  MANAGE_COMPANIES,
  GET_COUNTRIES,
  MANAGE_TENANT_SETTINGS,
  MANAGE_RESERVE_SLOTS,
  MANAGE_MASTER_SETTINGS,
  EMAIL_TEMPLATES,
  SMS_TEMPLATES,
  MANAGE_CHARGER_STATUS,
  MANAGE_OEM_VENDORS,
  MANAGE_OEM_CODES,
  MANAGE_PERMISSIONS,
  MANAGE_MODULES,
  MANAGE_ROLES,
  GET_ROLES,
  GET_OEMS,
  MANAGE_CHARGE_SPEEDS,
  GET_CONNECTOR_TYPES,
  MANAGE_PRIVACYS,
  MANAGE_FAQS,
  GET_OEM_VENDORS,
  GET_CS_ACCESS_TYPES,
  GET_ELECTRICITY_SUPPLIERS,
  GET_AMENITIES,
  GET_EV_CATALOGS,
  GET_EV_MASTER_DATAS,
  GET_AREAS,
  GET_CITYS,
  GET_STATES,
  GET_ALL_DATA_REPORT,
  GET_OCPI_PARTNER_CHARGING_REPORT,
  USER_DELETED_REQUEST,
  GET_PLANT_LOAD_FACTOR,
  // GET_LIVE_CHARGING_SESSIONS,
  GET_OCPI_PARTNER_GROUPS,
  GET_ADD_PARTNER_GROUP,
  GET_ADD_PARTY_ID,
  MANAGE_OCPI_PARTNER_GROUPS,
  ZMT_ADMIN,
  FINANCE_ADMIN,
  GET_COUPONS,
  MANAGE_COUPONS,
  GET_VOUCHER,
  MANAGE_VOUCHER,
  GET_HMIL_CUSTOM_CHARGING_REPORT,
  GET_ELECTRICITY_BILL,
  MANAGE_ELECTRICITY_BILL,
  GET_PARTNER_CHARGING_SUMMARY,
  GET_ALERTS,
  GET_OWNERSHIP_TYPE
  // GET_LIVE_CHARGING_SESSIONS
} from 'components/common/constant';

import Logout from 'view/logout/Logout';
import StationDetails from 'view/stations/StationDetails';
// import StationForm from 'view/stations/StationForm';
import ChargerDetail from 'view/charger/ChargerDetail';
import ChargerForm from 'view/charger/ChargerForm';
import VehicleList from 'view/vehicle/VehicleList';
import VehicleForm from 'view/vehicle/VehicleForm';
import TenantAdminList from 'view/tenantAdmin/TenantAdminList';
import TenantAdminDetails from 'view/tenantAdmin/TenantAdminDetails';
import TenantForm from 'view/tenant/TenantForm';
import FirmWareUpdate from 'view/firmWare/FirmWareUpdate';
import CustomerList from 'view/customerManagement/CustomerList';
import ChargerStatus from 'view/chargerStatus';
import TariffList from 'view/tariffManagement/TariffList';
import BillingInvoice from 'view/billingAndInvoice/BillingInvoice';
// import DataReportsList from 'view/dataReports/DataReportsList';
import ChargerActivityDetail from 'view/charger/ChargerActivityDetail';
import Setting from 'view/setting/Setting';
import ProfileForm from 'view/setting/ProfileForm';
import UserForm from 'view/setting/UserForm';
import SubscriptionList from 'view/subscriptions/SubscriptionList';
import CountryList from 'view/master/country/CountryList';
import StateList from 'view/master/state/StateList';
import CityList from 'view/master/city/CityList';
import AreaList from 'view/master/area/AreaList';
import MakeList from 'view/master/make/MakeList';
import OwnershipType from 'view/master/ownershipType/ownershipType';
import ModelList from 'view/master/model/ModelList';
import AmenityList from 'view/master/amenity/AmenityList';
import ElectricitySupplierList from 'view/master/electricitySupplier/ElectricitySupplierList';
import StationAccessTypeList from 'view/master/stationAccessType/StationAccessTypeList';
import OemVendorList from 'view/master/oemVendor/OemVendorList';
import FaqList from 'view/master/faq/FaqList';
import PrivacyList from 'view/master/privacy/PrivacyList';
import ConnectorTypeList from 'view/master/connectorType/ConnectorTypeList';
import ChargeSpeedList from 'view/master/chargeSpeed/ChargeSpeedList';
import OemList from 'view/master/oem/OemList';
import OemForm from 'view/master/oem/OemForm';
// import FaqsAndPrivacy from 'view/master/faqsAndPrivacy/FaqsAndPrivacy';
import RoleList from 'view/master/role/RoleList';
import ChargerStateList from 'view/master/chargerState/ChargerStateList';
import OemErrorCodeList from 'view/master/oemErrorCode/OemErrorCodeList';
import StationUnitConsumption from 'view/dataReports/StationUnitConsumption';
import OcpiPartnerChargingReport from 'view/dataReports/OcpiPartnerChargingReport';
import OCPIPartnerGroup from 'view/userGroup/OCPIPartnerGroup';
import PcsUtilizationReport from 'view/dataReports/PcsUtilizationReport';
import B2BStationChargingHistory from 'view/dataReports/B2BStationChargingHistory';
import B2CStationChargingHistory from 'view/dataReports/B2CStationChargingHistory';
import JsonRequestResponseReport from 'view/dataReports/JsonRequestResponseReport';
import WalletTransactionReport from 'view/dataReports/WalletTransactionReport';
import AppUserDetailsList from 'view/dataReports/AppUserDetailsList';
import MostVehicleChargedList from 'view/dataReports/MostVehicleChargedList';
import BookingHistory from 'view/dataReports/BookingHistory';
import UserChargingHistory from 'view/dataReports/UserChargingHistory';
import StationChargingSummary from 'view/dataReports/StationChargingSummary';
import BookingSummaryReport from 'view/dataReports/BookingSummaryReport';
import MoneyTransactionPGReport from 'view/dataReports/MoneyTransactionPGReport';
import RatingReviewReport from 'view/dataReports/RatingReviewReport';
import EditTenantForm from 'view/tenant/EditTenantForm';
import GeneralNotification from 'view/notification/GeneralNotification';
import PermissionList from 'view/master/permissions/PermissionList';
import OfferList from 'view/offers/OfferList';
import CouponList from 'view/offerManagement/coupon/CouponList';
import ChargingSessionForm from 'view/liveChargerSession/ChargingSessionForm';
import OfferForm from 'view/offers/OfferForm';
import UserGroupList from 'view/userGroup/UserGroupList';
import UserGroupForm from 'view/userGroup/UserGroupForm';
import { InvoiceTable } from 'view/billingAndInvoice/InvoiceInfo';
import Faq from 'view/master/faqsAndPrivacy/Faq';
import SmsList from 'view/master/sms/SmsList';
import EmailList from 'view/master/email/EmailList';
import ConfigureRole from 'view/master/role/ConfigureRole';
import RfidGroupList from 'view/rfidGroup/RfidGroupList';
import RfidList from 'view/rfid/RfidList';
import PublicUsers from 'view/scheduling/PublicUsers';
import RfidSetting from 'view/master/masterSetting/RfidSetting';
import RfidRequests from 'view/rfidRequests/RfidRequestList';
import UserList from 'view/userGroup/UserList';
import UpdateFirmware from 'view/notification/UpdateFirmware';
import AddUserForm from 'view/userGroup/AddUserForm';
import CustomerDetail from 'view/customerManagement/CustomerDetail';
import Slots from 'view/tariffManagement/Slots';
import StationDownTimeReport from 'view/dataReports/StationDownTimeReport';
import ACMeterReadingReport from 'view/dataReports/ACMeterReadingReport';
// import AppSetting from 'view/master/masterSetting/AppSetting';
import StationWiseAccess from 'view/setting/StationWiseAccess';
import ReserveSlotsList from 'view/reserveSlots/ReserveSlotsList';
// import Cpo from 'view/partnerManagement/CPO/index';
import Emsp from 'view/partnerManagement/EMSP';
// import AddCpo from 'view/partnerManagement/CPO/AddCpo';
import AddEmsp from 'view/partnerManagement/EMSP/AddEmsp';
import ChargingHistoryReport from 'view/dataReports/ChargingHistoryReport';
import JobVoucher from 'view/dataReports/JobVoucher';
// import InvoiceReport from 'view/dataReports/InvoicesReport';
import ChargeCoin from 'view/chargeCoin/ChargeCoin';
import UpdateApp from 'view/updateApp/UpdateApp';
import TenantAdminAccess from 'view/tenantAdmin/TenantAdminAccess';
import Capacityutilization from 'view/scheduling/Capacityutilisation';
import DownloadReports from 'view/DownloadReports/DownloadReports';
import OcpiPartyInfo from 'view/partnerManagement/EMSP/ocpiPartyInfo/OcpiPartyInfo';
import Negativemetervalue from 'view/Administartion/Negativemetervalue';
import SchedulingSetting from 'view/scheduling/schedulingSettings/SchedulingSetting';
// import CommunicationManager from 'view/notification/CommunicationManager';
import PaymentStatusReport from 'view/dataReports/PaymentStatusReport';
import tagmanagement from 'view/Administartion/tagmanagement';
import VehicleChargingSession from 'view/dataReports/VehicleChargingSession';
import OCPISetting from 'view/partnerManagement/OCPISetting';
import Dashboard from 'view/dashboard';
//  import ChargingSessionForm from 'view/liveChargingSessions/ChargingSessionForm';
import ChargerStationList from 'view/stations/StationList';
import FaultyBookingReport from 'view/Administartion/FaultyBookingReport';
import FleetManagement from 'view/fleetManagement/FleetList';
import FleetForm from 'view/fleetManagement/FleetForm';
import FleetDetail from 'view/fleetManagement/FleetDetail';
import DriverManagement from 'view/manageFleet/driverManagement/DriverList';
import DriverForm from 'view/manageFleet/driverManagement/DriverForm';
import DriverDetail from 'view/manageFleet/driverManagement/DriverDetail';
import Rating from 'view/RatingAndReview/Rating';
import NoLoadReport from 'view/dataReports/NoLoadReport';
import ElectriciyBillReport from 'view/dataReports/ElectricityBillReport';
import HMILCustomChargingReport from 'view/dataReports/HMILCustomChargingReport';
import alldatareport from 'view/dataReports/Alldatareport';
import TransactionReport from 'view/dataReports/TransactionReport';
import ChargingSessionReport from 'view/dataReports/ChargingSessionReport';
import ManageVehicle from 'view/manageFleet/manageVehicle/VehicleList';
import ManageVehicleForm from 'view/manageFleet/manageVehicle/VehicleForm';
import FleetRfidList from 'view/manageFleet/rfidList/RfidList';
import RatingDetails from 'view/RatingAndReview/RatingDetails';
import AccountDeleteRequest from 'view/Administartion/accountDeleteRequest/AccountDeleteRequest';
import PlantLoadFactorReport from 'view/dataReports/PlantLoadFactorReport';
import ModulesList from 'view/master/modules/ModuleList';
import ConfigureModule from 'view/master/modules/ConfigureModule';
import AddPartnerGroup from 'view/userGroup/AddPartnerGroup';
import AddPartyId from 'view/userGroup/AddPartyId';
import SelectPartyId from 'view/userGroup/SelectPartyId';
import AccessManagement from 'view/accessManagement/accessManagement';
import DownloadAdvanceReport from 'view/downloadAdvanceReport/DownloadAdvanceReport';
import AddElectricityBill from 'view/dataReports/AddElectricityBill';
import CustomerWalletTransactionReport from 'view/dataReports/CustomerWalletTransactionReport';
// import StationAccessToPartner from 'view/partnerManagement/EMSP/StationAccessToPartner';
import StationAccessList from 'view/partnerManagement/EMSP/stationAccessPartner/StationAccessList';
import StationAccessForm from 'view/partnerManagement/EMSP/stationAccessPartner/StationAccessForm';
import Alerts from 'view/notification/Alerts';
import VoucherList from 'view/offerManagement/voucher/VoucherList';
import VoucherForm from 'view/offerManagement/voucher/VoucherForm';
import VoucherLogs from 'view/offerManagement/voucher/VoucherLogs';
import CouponForm from 'view/offerManagement/coupon/CouponForm';
import CouponUsageReport from 'view/offerManagement/coupon/CouponUsageReport';
import CouponPerformanceReport from 'view/offerManagement/coupon/CouponPerformanceReport';
// import StationAccessToPartner from 'view/partnerManagement/EMSP/StationAccessToPartner';
import PartnerChargingSummary from 'view/partnerManagement/PartnerChargingSummaryReport';
import Difference from 'view/dataReports/Difference';
import VoucherAssignments from 'view/dataReports/VoucherAssignments';
import rejectedVoucher from 'view/dataReports/rejectedVouchers';
import StepForm from 'view/stations/Stepform';

export const routes = {
  dashboard: {
    path: '/dashboard',
    component: Dashboard,
    permission: 'getDashboardData',
    moduleKey: 'dashboard',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    showSuperAdmin: true,
  },
  liveChargingSession: {
    path: '/liveChargingSessions',
    component: ChargingSessionForm,
    permission: 'getLiveChargingSessions',
    moduleKey: 'liveChargingSession',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationList: {
    path: '/stations',
    moduleKey: 'stationsList',
    component: ChargerStationList,
    permission: GET_CHARGING_STATIONS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationDetails: {
    path: '/stations/:stationId',
    component: StationDetails,
    moduleKey: 'stationsList',
    permission: GET_CHARGING_STATIONS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationForms: {
    path: ['/addStation', '/editStation/:stationId'],
    component: StepForm,
    permission: MANAGE_CHARGING_STATIONS,
    moduleKey: 'addChargingStation',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  chargerDetail: {
    path: '/charger/:chargerId?',
    component: ChargerDetail,
    permission: GET_CHARGERS,
    moduleKey: 'stationsList',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  chargerForm: {
    path: ['/addCharger', '/editCharger/:chargerId'],
    component: ChargerForm,
    permission: MANAGE_CHARGERS,
    moduleKey: 'addCharger',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  userGroupList: {
    path: '/userGroupManagement',
    component: UserGroupList,
    permission: GET_USER_GROUPS,
    moduleKey: 'userGroupList',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  userGroupForm: {
    path: ['/addUserGroup', '/editUserGroup/:userGroupId'],
    component: UserGroupForm,
    permission: MANAGE_USER_GROUPS,
    moduleKey: 'modifyUserGroups',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  partnerGroupForm: {
    path: ['/addPartnerGroup', '/editPartnerGroup/:partnerGroupId'],
    component: AddPartnerGroup,
    permission: MANAGE_OCPI_PARTNER_GROUPS,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  vehicleList: {
    path: '/vehicles',
    component: VehicleList,
    permission: GET_VEHICLES,
    moduleKey: 'manageVehicle',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  vehicleForm: {
    path: ['/addVehicle', '/editVehicle/:vehicleId'],
    component: VehicleForm,
    permission: MANAGE_VEHICLES,
    moduleKey: 'manageVehicle',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  generalNotification: {
    path: '/notification',
    component: GeneralNotification,
    permission: GET_NOTIFICATIONS,
    moduleKey: 'notification',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    all: true,
  },
  alerts: {
    path: '/alerts',
    component: Alerts,
    permission: GET_ALERTS,
    moduleKey: 'alerts',
    roles: [TENANT_ADMIN, SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  updateFirmware: {
    path: '/updateFirmware',
    component: UpdateFirmware,
    permission: MANAGE_CHARGERS,
    moduleKey: 'updateFirmware',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  offerForm: {
    path: ['/addOffer', '/editOffer/:offerId'],
    component: OfferForm,
    permission: MANAGE_OFFERS,
    moduleKey: 'offers',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  offerList: {
    path: '/offers',
    component: OfferList,
    permission: GET_OFFERS,
    moduleKey: 'offers',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  couponList: {
    path: '/coupons',
    component: CouponList,
    permission: GET_COUPONS,
    moduleKey: 'coupons',
    roles: [ADMIN, TENANT_ADMIN],
  },
  ratingReview: {
    path: '/ratingReview',
    component: Rating,
    permission: GET_RATING,
    moduleKey: 'ratingReview',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  ratingDetails: {
    path: '/ratingDetails/:stationId',
    component: RatingDetails,
    permission: GET_RATING,
    moduleKey: 'ratingDetails',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  ocpiSetting: {
    path: '/OCPI-Setting',
    component: OCPISetting,
    permission: GET_TENANAT_SETTINGS,
    moduleKey: 'ocpiSettings',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  partnerChargingSummary: {
    path: '/partnerChargingSummary',
    component: PartnerChargingSummary,
    permission: GET_PARTNER_CHARGING_SUMMARY,
    moduleKey: 'PartnerStationChargingSummary',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  // cpo: {
  //   path: '/cpo',
  //   component: Cpo,
  //   permission: GET_OCPI,
  //   roles: [
  //     ADMIN,
  //     TENANT_ADMIN,
  //     GENTARI_CUSTOM_ROLE,
  //     FLEET_MEMBER,
  //     FLEET_OWNER,
  //     CPO,
  //     B2BCLIENT,
  //     FINANCE,
  //     DOCO,
  //     SUPPORT,
  //     SUPER_ADMIN,
  //     SUPPORT_ADMIN,
  //     NETWORK_ADMIN,
  //     CUSTOMER,
  //     GCM_CPO,
  //     MNM_DEALER,
  //     SAAS_ADMIN,
  //     MD_ADMIN,
  //     MD_DEALER,
  //   ],
  // },
  // addCpo: {
  //   path: ['/cpo/addCpo', '/cpo/:ocpiId'],
  //   component: AddCpo,
  //   permission: MANAGE_OCPI,
  //   roles: [
  //     ADMIN,
  //     TENANT_ADMIN,
  //     GENTARI_CUSTOM_ROLE,
  //     FLEET_MEMBER,
  //     FLEET_OWNER,
  //     CPO,
  //     B2BCLIENT,
  //     FINANCE,
  //     DOCO,
  //     SUPPORT,
  //     SUPER_ADMIN,
  //     SUPPORT_ADMIN,
  //     NETWORK_ADMIN,
  //     CUSTOMER,
  //     GCM_CPO,
  //     MNM_DEALER,
  //     SAAS_ADMIN,
  //     MD_ADMIN,
  //     MD_DEALER,
  //   ],
  // },
  emsp: {
    path: '/OCPI-Partner',
    component: Emsp,
    permission: GET_OCPI,
    moduleKey: 'ocpiPartyInfo',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  StationAccessList: {
    path: '/stationAccessToPartner/:ocpiId',
    component: StationAccessList,
    permission: MANAGE_OCPI,
    moduleKey: 'ocpiPartyInfo',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  StationAccessForm: {
    path: '/stationAccess/:ocpiId',
    component: StationAccessForm,
    permission: MANAGE_OCPI,
    moduleKey: 'ocpiPartyInfo',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  addEmsp: {
    path: ['/OCPI-Partner/addPartner', '/OCPI-Partner/:ocpiId'],
    component: AddEmsp,
    permission: MANAGE_OCPI,
    moduleKey: 'ocpiPartyInfo',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  ocpiPartyInfo: {
    path: '/ocpiPartyInfo/:ocpiId',
    component: OcpiPartyInfo,
    moduleKey: 'ocpiPartyInfo',
    permission: GET_OCPI,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  chargerActivityDetail: {
    path: '/activityLog',
    component: ChargerActivityDetail,
    permission: GET_CHARGER_LOGS,
    moduleKey: 'activityLog',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  customerList: {
    path: '/customers',
    component: CustomerList,
    permission: GET_CUSTOMERS,
    moduleKey: 'customerList',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  customerDetail: {
    path: '/customerProfile/:customerId',
    component: CustomerDetail,
    permission: GET_CUSTOMERS,
    moduleKey: 'viewCustomerProfileCustomerManagement',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  fleets: {
    path: '/fleets',
    component: FleetManagement,
    permission: GET_FLEET_MEMBERS,
    moduleKey: 'b2cFleet',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  addFleets: {
    path: '/fleet/addFleet',
    component: FleetForm,
    permission: MANAGE_FLEET_MEMBERS,
    moduleKey: 'b2cFleet',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  detailFleets: {
    path: '/fleetProfile/:fleetId',
    component: FleetDetail,
    permission: GET_FLEET_MEMBERS,
    moduleKey: 'b2cFleet',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  drivers: {
    path: '/drivers',
    component: DriverManagement,
    permission: GET_FLEET_MEMBERS,
    moduleKey: 'manageDrivers',
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  addDrivers: {
    path: '/driver/addDriver',
    component: DriverForm,
    permission: MANAGE_FLEET_MEMBERS,
    moduleKey: 'manageDrivers',
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  detailDrivers: {
    path: '/driverProfile/:driversId',
    component: DriverDetail,
    permission: GET_FLEET_MEMBERS,
    roles: [FLEET_MEMBER, FLEET_OWNER],
    moduleKey: 'manageDrivers',
  },
  manageVehicle: {
    path: '/Manage-Vehicles',
    component: ManageVehicle,
    moduleKey: 'manageVehicles',
    permission: GET_VEHICLES,
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  manageVehicleForm: {
    path: ['/Manage-Vehicle/addVehicle', '/Manage-Vehicle/:vehicleId'],
    component: ManageVehicleForm,
    permission: MANAGE_VEHICLES,
    moduleKey: 'manageVehicles',
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  FleetRfidList: {
    path: ['/fleet/RFID-List'],
    component: FleetRfidList,
    permission: GET_RFID_GROUPS,
    moduleKey: 'rfidlist',
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  chargerStatus: {
    path: '/chargerStatus',
    moduleKey: 'chargerStatus',
    component: ChargerStatus,
    permission: GET_CHARGING_STATIONS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  tariffList: {
    path: '/tariffs',
    component: TariffList,
    moduleKey: 'tariffList',
    permission: GET_TARRIFS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  slots: {
    path: ['/addSlot', '/editaddSlot/:slotId'],
    component: Slots,
    moduleKey: 'tariffList',
    permission: MANAGE_TARRIFS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  billingInvoice: {
    path: '/billingInvoice',
    moduleKey: 'invoiceReport',
    component: BillingInvoice,
    permission: GET_CHARGER_BOOKINGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  fleetTransactionReport: {
    path: '/fleet/transactionReport',
    component: TransactionReport,
    permission: GET_REPORT_TRANSACTION,
    moduleKey: 'fleetTransactionReport',
    roles: [FLEET_MEMBER, FLEET_OWNER],
  },
  chargingSessionReport: {
    path: '/chargingSessionReport',
    component: ChargingSessionReport,
    permission: GET_REPORT_CHARGING_SESSION,
    roles: [FLEET_MEMBER, FLEET_OWNER],
    moduleKey: 'chargingSessionReport',
  },
  stationUnitConsumption: {
    path: '/stationUnitConsumption',
    component: StationUnitConsumption,
    moduleKey: 'stationUnitConsumption',
    permission: GET_CHARGING_STATIONS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  OcpiPartnerChargingReport: {
    path: '/ocpiPartnerChargingReport',
    moduleKey: 'ocpiPartnerChargingReport',
    component: OcpiPartnerChargingReport,
    permission: GET_OCPI_PARTNER_CHARGING_REPORT,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  OCPIPartnerGroup: {
    path: '/OCPIPartnerGroup',
    component: OCPIPartnerGroup,
    permission: GET_OCPI_PARTNER_GROUPS,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  AddPartnerGroup: {
    path: '/AddPartnerGroup',
    component: OCPIPartnerGroup,
    permission: GET_ADD_PARTNER_GROUP,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  AddPartyId: {
    path: '/AddPartyId',
    component: AddPartyId,
    permission: GET_ADD_PARTY_ID,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  ratingReviewReport: {
    path: '/ratingReviewReport',
    component: RatingReviewReport,
    permission: GET_RATING,
    moduleKey: 'ratingReviewReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  pcsUtilizationReport: {
    path: '/PcsUtilizationReport',
    component: PcsUtilizationReport,
    permission: GET_CHARGING_STATIONS,
    moduleKey: 'plantLoadFactorReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  chargingHistoryReport: {
    path: '/chargingHistoryReport',
    component: ChargingHistoryReport,
    permission: GET_MY_BOOKINGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'chargingHistoryReport',
  },
  b2bStationChargingHistory: {
    path: '/b2bStationChargingHistory',
    component: B2BStationChargingHistory,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'privateStationChargingSummary',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  b2cStationChargingHistory: {
    path: '/b2cStationChargingHistory',
    component: B2CStationChargingHistory,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'publicStationChargingHistory',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  jsonRequestResponseReport: {
    path: '/jsonRequestResponseReport',
    component: JsonRequestResponseReport,
    permission: GET_CHARGER_LOGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'jsonRequestResponseReport',
  },
  alldatareport: {
    path: '/Alldatareport',
    component: alldatareport,
    permission: GET_ALL_DATA_REPORT,
    moduleKey: 'alldatareport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  walletTransactionReport: {
    path: '/walletTransactionReport',
    component: WalletTransactionReport,
    permission: GET_WALLETS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'walletTransactionReport',
  },
  plantLoadFactorReport: {
    path: '/plantLoadFactorReport',
    component: PlantLoadFactorReport,
    moduleKey: 'plantLoadFactorReport',
    permission: GET_PLANT_LOAD_FACTOR,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  appUserDetailsList: {
    path: '/appUserDetailsList',
    component: AppUserDetailsList,
    moduleKey: 'appUserDetailsList',
    permission: GET_CUSTOMERS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  mostVehicleChargedList: {
    path: '/mostVehicleChargedList',
    component: MostVehicleChargedList,
    permission: GET_CHARGER_BOOKINGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'mostVehicleChargedList',
  },
  bookingHistory: {
    path: '/bookingHistory',
    component: BookingHistory,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'bookingHistory',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationDownTimeReport: {
    path: '/stationDownTimeReport',
    component: StationDownTimeReport,
    permission: GET_CHARGER_DOWNTIMES,
    moduleKey: 'stationDownTimeReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  userChargingHistory: {
    path: '/userChargingHistory',
    component: UserChargingHistory,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'userChargingHistory',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationChargingSummary: {
    path: '/stationChargingSummary',
    component: StationChargingSummary,
    permission: GET_CHARGING_STATIONS,
    moduleKey: 'stationChargingSummary',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  bookingSummaryReport: {
    path: '/bookingSummaryReport',
    component: BookingSummaryReport,
    permission: GET_CHARGER_BOOKINGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'bookingSummaryReport',
  },
  moneyTransactionPGReport: {
    path: '/moneyTransactionPGReport',
    component: MoneyTransactionPGReport,
    permission: GET_PAYMENTS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
    moduleKey: 'moneyTransactionPGReport',
  },
  acMeterReadingReport: {
    path: '/acMeterReadingReport',
    component: ACMeterReadingReport,
    permission: GET_MQTT_LOGGER,
    moduleKey: 'acMeterReadingReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  jobVoucher: {
    path: '/jobVoucher',
    component: JobVoucher,
    permission: GET_MASTER_SETTINGS,
    moduleKey: 'jobVoucher',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
    difference: {
    path: '/difference',
    component: Difference,
    permission: GET_MASTER_SETTINGS,
    moduleKey: 'difference',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  voucherAssignment: {
    path: '/voucherAssignments',
    component: VoucherAssignments,
    permission: GET_MASTER_SETTINGS,
    moduleKey: 'voucherAssignments',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  rejectedVoucher: {
    path: '/rejectedVoucher',
    component: rejectedVoucher,
    permission: GET_MASTER_SETTINGS,
    moduleKey: 'rejectedVoucher',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  vehicleChargingSession: {
    path: '/vehicleChargingSession',
    component: VehicleChargingSession,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'vehicleChargingSession',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  paymentStatusReport: {
    path: '/paymentStatusReport',
    component: PaymentStatusReport,
    permission: GET_PAYMENTS,
    moduleKey: 'paymentStatusReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  couponUsageReport: {
    path: '/couponUsageReport',
    component: CouponUsageReport,
    moduleKey: 'couponUsageReport',
    permission: GET_COUPONS,
    roles: [ADMIN, TENANT_ADMIN],
  },
  couponPerformanceReport: {
    path: '/couponPerformanceReport',
    component: CouponPerformanceReport,
    moduleKey: 'couponPerformanceReport',
    permission: GET_COUPONS,
    roles: [ADMIN, TENANT_ADMIN],
  },
  userList: {
    path: '/user/:userGroupId',
    component: UserList,
    permission: GET_CUSTOMERS,
    moduleKey: 'userGroupList',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  partyList: {
    path: '/party/:partnerGroupId',
    component: AddPartyId,
    permission: GET_CUSTOMERS,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  AddElectricityBill: {
    path: ['/AddElectricityBill', '/EditElectricityBill/:billId'],
    component: AddElectricityBill,
    permission: MANAGE_ELECTRICITY_BILL,
    moduleKey: 'AddElectricityBill',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  ElectriciyBillReport: {
    path: '/ElectriciyBillReport',
    component: ElectriciyBillReport,
    permission: GET_ELECTRICITY_BILL,
    moduleKey: 'ElectriciyBillReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  addPartyForm: {
    path: '/addParty/:partnerGroupId',
    component: SelectPartyId,
    permission: MANAGE_USERS,
    moduleKey: 'OCPIPartnerGroup',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  addUserForm: {
    path: '/addUser/:userGroupId',
    component: AddUserForm,
    permission: MANAGE_USERS,
    moduleKey: 'userGroupList',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  stationWiseAccess: {
    path: '/stationWiseAccess/:userId',
    component: StationWiseAccess,
    permission: GET_CHARGING_STATIONS,
    moduleKey: 'modifyAccessManagement',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  rfidGroup: {
    path: '/rfidGroup',
    component: RfidGroupList,
    permission: GET_RFID_GROUPS,
    moduleKey: 'rfidMaster',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  rfidList: {
    path: '/rfidList/:rfidGroupId',
    component: RfidList,
    permission: GET_RFID_GROUPS,
    moduleKey: 'rfidMaster',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  rfidRequests: {
    path: '/rfidRequests',
    component: RfidRequests,
    permission: GET_RFID_REQUESTS,
    moduleKey: 'rfidRequests',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  publicUsers: {
    path: '/publicUsers',
    component: PublicUsers,
    permission: GET_MY_BOOKINGS,
    moduleKey: 'publicUsers',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  invoiceTable: {
    path: '/invoice/:invoiceId',
    component: InvoiceTable,
    permission: GET_CHARGER_BOOKINGS,
    moduleKey: 'invoiceTable',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  chargeCoin: {
    path: '/chargeCoin',
    component: ChargeCoin,
    permission: UPDATE_MY_PROFILE,
    moduleKey: 'chargeCoin',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  capacityutilization: {
    path: '/Capacityutilization',
    component: Capacityutilization,
    permission: GET_CHARGER_STATES,
    moduleKey: 'capacityutilization',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  CustomerWalletTransactionReport: {
    path: '/customerWalletTransaction',
    component: CustomerWalletTransactionReport,
    permission: '',
    moduleKey: 'getCustomerWalletTransaction',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  downloadAdvanceReport: {
    path: '/downloadAdvanceReport',
    component: DownloadAdvanceReport,
    permission: '',
    moduleKey: 'downloadAdvanceReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  negativemetervalue: {
    path: '/Negativemetervalue',
    component: Negativemetervalue,
    permission: STOP_TRANSACTION,
    moduleKey: 'negativemetevalue',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  tagmanagement: {
    path: '/tagmanagement',
    moduleKey: 'tagmanagement',
    component: tagmanagement,
    permission: GET_TAGS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  downloadReports: {
    path: '/DownloadReports',
    component: DownloadReports,
    permission: '',
    moduleKey: 'downloadReports',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  accountDeleteRequest: {
    path: '/accountDeleteRequest',
    component: AccountDeleteRequest,
    permission: USER_DELETED_REQUEST,
    moduleKey: 'accountDeleteRequest',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  schedulingSetting: {
    path: '/schedulingSetting',
    component: SchedulingSetting,
    permission: GET_TENANAT_SETTINGS,
    moduleKey: 'schedulingSetting',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  faultyBooking: {
    path: '/faultyBookingReport',
    component: FaultyBookingReport,
    permission: GET_FAULTY_BOOKINGS,
    moduleKey: 'faultybookings',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  noLoadReport: {
    path: '/noLoadReport',
    component: NoLoadReport,
    permission: MANAGE_NO_LOAD_REPORT,
    moduleKey: 'noLoadReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },

  HMILCustomChargingReport: {
    path: '/HMILCustomChargingReport',
    component: HMILCustomChargingReport,
    permission: GET_HMIL_CUSTOM_CHARGING_REPORT,
    moduleKey: 'HMILCustomChargingReport',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },

  tenantAdmin: {
    path: '/tenantAdmin',
    component: TenantAdminList,
    permission: MANAGE_COMPANIES,
    moduleKey: 'tenantAdmin',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN, ZMT_ADMIN, FINANCE_ADMIN],
    showSuperAdmin: true,
  },
  tenantAdminDetails: {
    path: '/tenantAdmin/:tenantId',
    component: TenantAdminDetails,
    permission: MANAGE_COMPANIES,
    moduleKey: 'tenantAdminDetails',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  tenantForm: {
    path: '/addTenant',
    component: TenantForm,
    permission: MANAGE_COMPANIES,
    moduleKey: 'tenantForm',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN],
  },
  edittenantForm: {
    path: '/editTenant/:tenantId',
    component: EditTenantForm,
    permission: MANAGE_COMPANIES,
    moduleKey: 'edittenantForm',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN],
  },
  subscriptions: {
    path: '/subscriptions',
    component: SubscriptionList,
    permission: MANAGE_COMPANIES,
    moduleKey: 'subscriptions',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  country: {
    path: '/country',
    component: CountryList,
    permission: GET_COUNTRIES,
    moduleKey: 'country',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  state: {
    path: '/state',
    component: StateList,
    permission: GET_STATES,
    moduleKey: 'state',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  city: {
    path: '/city',
    component: CityList,
    permission: GET_CITYS,
    moduleKey: 'city',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  area: {
    path: '/area',
    component: AreaList,
    permission: GET_AREAS,
    moduleKey: 'area',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  make: {
    path: '/make',
    component: MakeList,
    permission: GET_EV_MASTER_DATAS,
    moduleKey: 'make',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  model: {
    path: '/model',
    component: ModelList,
    permission: GET_EV_CATALOGS,
    moduleKey: 'model',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  amenity: {
    path: '/amenity',
    component: AmenityList,
    permission: GET_AMENITIES,
    moduleKey: 'amenity',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  electricitySupplier: {
    path: '/electricitySupplier',
    component: ElectricitySupplierList,
    moduleKey: 'electricitySupplier',
    permission: GET_ELECTRICITY_SUPPLIERS,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  stationAccessType: {
    path: '/stationAccessType',
    component: StationAccessTypeList,
    permission: GET_CS_ACCESS_TYPES,
    moduleKey: 'stationAccessType',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  oem: {
    path: '/oem',
    component: OemVendorList,
    permission: GET_OEM_VENDORS,
    moduleKey: 'oem',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  faq: {
    path: '/faq',
    component: FaqList,
    permission: MANAGE_FAQS,
    moduleKey: 'faq',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  faqs: {
    path: '/faqs',
    component: Faq,
    permission: MANAGE_FAQS,
    moduleKey: 'faqs',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  privacy: {
    path: '/privacy',
    component: PrivacyList,
    permission: MANAGE_PRIVACYS,
    moduleKey: 'privacy',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  connectorType: {
    path: '/connectorType',
    component: ConnectorTypeList,
    permission: GET_CONNECTOR_TYPES,
    moduleKey: 'connectorType',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  chargeSpeed: {
    path: '/chargeSpeed',
    component: ChargeSpeedList,
    permission: MANAGE_CHARGE_SPEEDS,
    moduleKey: 'chargeSpeed',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  chargeSpot: {
    path: '/ChargeSpot',
    component: OemList,
    permission: GET_OEMS,
    moduleKey: 'chargeSpot',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  ownershipType: {
    path: '/OwnershipType',
    component: OwnershipType,
    permission: GET_OWNERSHIP_TYPE,
    moduleKey: 'ownershipType',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  role: {
    path: '/role',
    component: RoleList,
    permission: GET_ROLES,
    moduleKey: 'role',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  configureRole: {
    path: '/configureRole/:roleId',
    component: ConfigureRole,
    permission: MANAGE_ROLES,
    moduleKey: 'configureRole',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  tenantAdminAccess: {
    path: '/tenantAdminAccess/:roleId',
    component: TenantAdminAccess,
    permission: MANAGE_MODULES,
    moduleKey: 'tenantAdminAccess',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  permissions: {
    path: '/permissions',
    component: PermissionList,
    moduleKey: 'permissions',
    permission: MANAGE_PERMISSIONS,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  oemErrorCode: {
    path: '/oemErrorCode',
    component: OemErrorCodeList,
    permission: MANAGE_OEM_CODES,
    moduleKey: 'oemErrorCode',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  oemForm: {
    path: ['/addChargeSpot', '/editChargeSpot/:oemId'],
    component: OemForm,
    permission: MANAGE_OEM_CODES,
    moduleKey: 'oemForm',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  firmWareUpdate: {
    path: '/firmWareUpdate',
    component: FirmWareUpdate,
    permission: MANAGE_OEM_VENDORS,
    moduleKey: 'firmWareUpdate',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  chargerState: {
    path: '/chargerState',
    component: ChargerStateList,
    permission: MANAGE_CHARGER_STATUS,
    moduleKey: 'chargerState',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  sms: {
    path: '/sms',
    component: SmsList,
    permission: SMS_TEMPLATES,
    moduleKey: 'sms',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  email: {
    path: '/email',
    component: EmailList,
    permission: EMAIL_TEMPLATES,
    moduleKey: 'email',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  rfidSetting: {
    path: '/rfid_settings/:rfidSettingId',
    component: RfidSetting,
    moduleKey: 'rfidSetting',
    permission: MANAGE_MASTER_SETTINGS,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  modules: {
    path: '/modules',
    component: ModulesList,
    moduleKey: 'modules',
    showSuperAdmin: true,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
  },
  configureModule: {
    path: '/configureModule/:moduleId',
    component: ConfigureModule,
    moduleKey: 'configureModule',
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN],
    showSuperAdmin: true,
  },
  reserveSlots: {
    moduleKey: 'reserveSlots',
    path: '/reserveSlots',
    component: ReserveSlotsList,
    permission: MANAGE_RESERVE_SLOTS,
    roles: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN, ADMIN, TENANT_ADMIN, GENTARI_CUSTOM_ROLE, FLEET_MEMBER, FLEET_OWNER, MD_ADMIN, MD_DEALER],
  },
  updateApp: {
    path: '/updateApp',
    component: UpdateApp,
    permission: MANAGE_TENANT_SETTINGS,
    moduleKey: 'updateApp',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  accessManagement: {
    path: '/accessManagement',
    component: AccessManagement,
    moduleKey: 'accessManagement',
  },
  userForm: {
    path: '/accessManagement/addUser',
    component: UserForm,
    moduleKey: 'modifyAccessManagement',
    permission: MANAGE_USERS,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      FLEET_MEMBER,
      FLEET_OWNER,
      CPO,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  voucherList: {
    path: '/vouchers',
    component: VoucherList,
    permission: GET_VOUCHER,
    moduleKey: 'vouchers',
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  vouchers: {
    path: ['/addVoucher', '/editVoucher/:voucherId'],
    component: VoucherForm,
    moduleKey: 'vouchers',
    permission: MANAGE_VOUCHER,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  voucherLog: {
    path: '/voucherLog',
    component: VoucherLogs,
    moduleKey: 'voucherLogs',
    permission: MANAGE_VOUCHER,
    roles: [
      ADMIN,
      TENANT_ADMIN,
      GENTARI_CUSTOM_ROLE,
      B2BCLIENT,
      FINANCE,
      DOCO,
      SUPPORT,
      SUPER_ADMIN,
      SUPPORT_ADMIN,
      NETWORK_ADMIN,
      CUSTOMER,
      GCM_CPO,
      MNM_DEALER,
      SAAS_ADMIN,
      MD_ADMIN,
      MD_DEALER,
    ],
  },
  setting: {
    path: '/setting',
    component: Setting,
    permission: UPDATE_MY_PROFILE, // to all
    all: true,
    moduleKey: 'setting',
  },
  profileForm: {
    path: '/setting/profile',
    component: ProfileForm,
    permission: UPDATE_MY_PROFILE, // to all
    all: true,
  },
  logout: {
    path: '/logout',
    component: Logout,
    permission: UPDATE_MY_PROFILE, // to all
    all: true,
  },
  coupons: {
    path: ['/addCoupon', '/editCoupon/:couponId'],
    component: CouponForm,
    moduleKey: 'coupons',
    permission: MANAGE_COUPONS,
    roles: [ADMIN, TENANT_ADMIN],
  },
  //   communicationManager: {
  //     path: '/communicationManager',
  //     component: lazy(() => {
  //       return Promise.all([import('view/notification/CommunicationManager'), new Promise((resolve) => setTimeout(resolve, 0))]).then(
  //         ([moduleExports]) => moduleExports
  //       );
  //     }),
  //     permission: 'sendNotfication',
  //     roles: [ADMIN,TENANT_ADMIN,GENTARI_CUSTOM_ROLE, CPO, B2BCLIENT, FINANCE, DOCO, SUPPORT, SUPER_ADMIN, CUSTOMER, GCM_CPO, MNM_DEALER, SAAS_ADMIN, MD_ADMIN, MD_DEALER],
  //   },
  //   dataReportsList: {
  //     path: '/dataReport',
  //     component: lazy(() => {
  //       return Promise.all([import('view/dataReports/DataReportsListr'), new Promise((resolve) => setTimeout(resolve, 0))]).then(
  //         ([moduleExports]) => moduleExports
  //       );
  //     }),
  //     permission: '',
  //     roles: [ADMIN, TENANT_ADMIN,GENTARI_CUSTOM_ROLE],
  //   },
};
