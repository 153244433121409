import { get  , post , downloadFile} from 'utils/api';

export const getSMCGateway = (data) => {
  return get(`/util/smcgateway`, data);
};

export const getBRVReports = (data) => {
  return post(`/report/getBRVReports`, data);
};

export const getJVReports = (data) => {
  return post(`/report/getJVReports`, data);
};

export const downloadBRVReport = (data) => {
  const id = data.reportId
  delete data['reportId'];
  return downloadFile(`/report/downloadBRV/${id}`, data);
};

export const downloadJVReport = (data) => {
  const id = data.reportId
  delete data['reportId'];
  return downloadFile(`/report/downloadJV/${id}`, data);
};

export const assignVoucherNumber = (data) =>{
  const id = data.reportId
  delete data['reportId'];
  return post(`/report/assignVouchernumber/${id}`, data)
}


//-----------------------------------------------------------------NEW

export const fetchVoucherList = (data) => {
  return post(`/report/fetchVouchersList`, data);
};

export const viewVoucher = (data) => {
  const voucher_id = data.reportId;
  return downloadFile(`/report/viewVoucher?voucherId=${voucher_id}`);
};

export const submitVoucherStatus = (data) => {
  const voucher_id = data.reportId;
  return downloadFile(`/report/submitVoucherStatus?voucherId=${voucher_id}`,data);
};

export const assignVoucherDetails = (data) => {
  const voucher_id = data.id;
  return post(`/report/assignVoucherDetails?voucherId=${voucher_id}`,data);
};




export const smcGateway = {
  getSMCGateway,
  getBRVReports,
  getJVReports,
  downloadBRVReport,
  downloadJVReport,
  assignVoucherNumber,

  fetchVoucherList,
  viewVoucher,
  submitVoucherStatus,
  assignVoucherDetails
};
