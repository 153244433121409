import { DOWNLOAD_BRV_REPORT, DOWNLOAD_JV_REPORT, FETCH_VOUCHER_LIST, GET_BRV_REPORTS, GET_JV_REPORTS, SUBMIT_VOUCHER_STATUS, VIEW_VOUCHER, ASSIGN_VOUCHER_DETAILS } from 'actions/smcGateway';

const initialValues = {
  isLoading: true,
  pdfpayload: '',
  reports: ''
};

const smcReportsReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case GET_BRV_REPORTS.REQUEST:
    case GET_JV_REPORTS.REQUEST:
    case DOWNLOAD_BRV_REPORT.REQUEST:
    case DOWNLOAD_JV_REPORT.REQUEST:
    case FETCH_VOUCHER_LIST.REQUEST:
    case VIEW_VOUCHER.REQUEST:
    case SUBMIT_VOUCHER_STATUS.REQUEST:
    case ASSIGN_VOUCHER_DETAILS.REQUEST:
      {
        return { ...state, isLoading: true };
      }

    case GET_BRV_REPORTS.SUCCESS:
    case GET_JV_REPORTS.SUCCESS:
    case FETCH_VOUCHER_LIST.SUCCESS:
    case SUBMIT_VOUCHER_STATUS.SUCCESS:
    case ASSIGN_VOUCHER_DETAILS.SUCCESS:

      {
        return {
          ...state,
          isLoading: false,
          reports: payload,
        };
      }
      
    case VIEW_VOUCHER.SUCCESS:
    case DOWNLOAD_BRV_REPORT.SUCCESS:
    case DOWNLOAD_JV_REPORT.SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          pdfpayload: payload,
        };
      }

    case GET_BRV_REPORTS.FAIL:
    case GET_JV_REPORTS.FAIL:
    case DOWNLOAD_BRV_REPORT.FAIL:
    case DOWNLOAD_JV_REPORT.FAIL:
    case FETCH_VOUCHER_LIST.FAIL:
    case VIEW_VOUCHER.FAIL:
    case SUBMIT_VOUCHER_STATUS.FAIL:
    case ASSIGN_VOUCHER_DETAILS.FAIL:
      {
        return { ...state, isLoading: false };
      }
    default:
      return { ...state };
  }
};

export default smcReportsReducer;
