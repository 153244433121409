import React, { useCallback, useState /* useContext*/ } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import * as _ from 'lodash';
import * as Yup from 'yup';
import Button from 'components/inputs/Button';
import TextInput from 'components/inputs/Input';
import ErrorMessage from 'components/inputs/ErrorMessage';
import PasswordStrengthBar from 'components/inputs/PasswordStrengthBar';
import { CREATE_NEW_PASSWORD } from 'actions/authAction';
import { toast } from 'react-toastify';
import { FaCheck, FaTimes } from 'react-icons/fa';
// import { NavContext } from 'components/privateRoute/PrivateRoute';

const ResetPassword = (props) => {
  const { phoneData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(null);
  const history = useNavigate();
  // const { navigateTo } = useContext(NavContext);
  const [errorMsg, setErrorMsg] = useState('');

  const resetPassword = useCallback((data) => {
    dispatch({
      type: CREATE_NEW_PASSWORD.REQUEST,
      payload: { ...data, ...phoneData },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Password change successfully');
            history.push('/login');
          } else {
            setErrorMsg(res.data.message);
          }
        }
      },
    });
  }, []);

  const ResetSchema = Yup.object().shape({
    password: Yup.string().required(t('resetPassword.password')).trim(t('resetPassword.space')).strict(true),
    confirm_password: Yup.string()
      .oneOf([Yup.ref(t('resetPassword.refpassword')), null], t('resetPassword.passCheck'))
      .required(t('resetPassword.confirmPassword'))
      .trim(t('resetPassword.space'))
      .strict(true),
    otp: Yup.string().required(t('resetPassword.otp')).min(4, t('resetPassword.otpmin')),
  });

  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);

  return (
    <div className="resetPassword-content--inner">
      <div className="title">Create New Password</div>
      <div className="resetPassword-form__block">
        <Formik
          initialValues={{ username: '', password: '', confirm_password: '', otp: '' }}
          validationSchema={ResetSchema}
          onSubmit={(values, { setSubmitting }) => {
            const resetPasswordValue = _.omit(values, ['confirm_password']);
            resetPassword(resetPasswordValue);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-content-block">
                <Row>
                  <Col lg={12}>
                    <div className="otp-input__main">
                      <div className="otp-inner-text">
                        <span>
                          {t('resetPassword.enterCode')} {`+${_.get(phoneData, 'country_code')} ${_.get(phoneData, 'phone')}`}
                        </span>
                      </div>
                      <div className="otp-input--box">
                        <div className="otp-input-box--inner">
                        <OtpInput
                            inputStyle="otp--box"
                            name="otp"
                            value={values.otp}
                            onChange={(code) => setFieldValue('otp', code)}
                            numInputs={4}
                            separator={<span style={{ width: '1.5rem' }} />}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            renderInput={(props) => <input {...props} />} 
                            />

                          {errors.otp && touched.otp ? (
                            <ErrorMessage>{errors.otp}</ErrorMessage>
                          ) : errorMsg ? (
                            <ErrorMessage>{errorMsg}</ErrorMessage>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="password_div resetpass">
                    <TextInput label={t('resetPassword.userName')} name="username" value={values.username} onChange={handleChange} />
                  </Col>
                  <Col lg={12} className="password_div resetpass">
                    <TextInput
                      label={t('resetPassword.passwordLabel')}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      value={values.password}
                      onChange={(e) => setFieldValue('password', e.target.value)}
                      error={errors.password && touched.password ? errors.password : null}
                    />
                    <div
                      type="button"
                      className="password-toggle-button"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        cursor: 'pointer',
                        transition: 'color 0.3s ease',
                        color: showPassword ? 'white' : '#be210b',
                      }}
                    >
                      {showPassword ? <FiEye /> : <FiEyeOff />}
                    </div>
                    {values.password.length > 0 && <PasswordStrengthBar password={values.password} />}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="password_div resetpass">
                    <TextInput
                      label={t('resetPassword.confirmPasswordLabel')}
                      name="confirm_password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={values.confirm_password}
                      onChange={(e) => {
                        setFieldValue('confirm_password', e.target.value);
                        setPasswordMatch(e.target.value === values.password);
                      }}
                      error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : null}
                      style={{
                        border: isConfirmPasswordFocused ? (passwordMatch ? '2px solid #19f600' : '2px solid red') : '2px solid #ccc',
                      }}
                      onFocus={() => setIsConfirmPasswordFocused(true)}
                      onBlur={() => setIsConfirmPasswordFocused(false)}
                    />
                    <div
                      type="button"
                      className="password-toggle-button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      style={{
                        cursor: 'pointer',
                        transition: 'color 0.3s ease',
                        color: showConfirmPassword ? 'white' : '#be210b',
                      }}
                    >
                      {showConfirmPassword ? <FiEye /> : <FiEyeOff />}
                    </div>
                    {values.confirm_password.length > 0 && <PasswordStrengthBar password={values.confirm_password} />}
                    <div className="icon-and-message">
                      {values.confirm_password.length > 0 &&
                        isConfirmPasswordFocused && ( // Added isConfirmPasswordFocused check
                          <div className="icon-container">
                            {passwordMatch ? <FaCheck className="check-icon" /> : <FaTimes className="cross-icon" />}
                          </div>
                        )}
                      {values.confirm_password.length > 0 &&
                        isConfirmPasswordFocused && ( // Added isConfirmPasswordFocused check
                          <div className="message-container">
                            {passwordMatch ? (
                              <span className="match-text" style={{ color: '#19f600' }}>
                                {t('resetPassword.passwordMatch')}
                              </span>
                            ) : (
                              <span className="mismatch-text">{t('resetPassword.passwordNoMatch')}</span>
                            )}
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
                <div className="form-button--block">
                  <Button type="submit" className="reset--btn">
                    {t('button.resetPassword')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  phoneData: PropTypes.func,
};

export default ResetPassword;
