import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SMC_PAYMENT_GATEWAY, ASSIGN_VOUCHER_DETAILS, SUBMIT_VOUCHER_STATUS, FETCH_VOUCHER_LIST, VIEW_VOUCHER } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { MdClose } from 'react-icons/md';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';
import CustomDatePickerInput from 'components/inputs/DatePickerInput';
import { toast } from 'react-toastify';
import TextInput from 'components/inputs/Input';
import fileDownload from 'js-file-download';



const Difference = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDate, setIsDate] = useState(false);
  const [isVoucher, setIsVoucher] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedReportId, setSelectedReportId] = useState(null); // State for the selected report ID
  const [voucherNumber, setVoucherNumber] = useState(''); // State for voucher number
  const [voucherDate, setVoucherDate] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [loadingReportId, setLoadingReportId] = useState(null);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const [matchAmount, setMatchAmount] = useState(''); // State to store match amount
  const [pdfPreviewId, setpdfPreviewId] = useState(null);
  const [reportTypePreview, setReportTypePreview] = useState('');
  const [voucherTypePreview, setVoucherTypePreview] = useState('');

  const reportList = useSelector((state) => state.smcReports.reports);

  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day').utc());
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day').utc());

  const handleAssignVoucherClick = (reportId, voucherNumber, voucherDate) => {
    setSelectedReportId(reportId); // Set the selected report ID
    setIsDate(voucherDate)
    setIsVoucher(voucherNumber)
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setVoucherNumber(''); // Reset the voucher number
  };
  const handleVoucherSubmit = () => {
    dispatch({
      type: ASSIGN_VOUCHER_DETAILS.REQUEST,
      payload: {
        reportId: selectedReportId,
        voucherNumber,
        voucherDate
      },
    });
    handleModalClose(); // Close the modal after dispatching
  };

  const initialValues = {
    from: moment(startDate).tz(countryTimezone).utc(),
    to: moment(endDate).tz(countryTimezone).utc(),

  };

  const getSMCGateway = useCallback((data) => {
    dispatch({ type: GET_SMC_PAYMENT_GATEWAY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getSMCGateway();
  }, []);


  //FETCH VOUCHER LIST DAY WISE
  const fetchAllVoucherList = (data) => {
    const payload = {
      ...data,
      from: moment(startDate).utc().startOf('day'), // 00:00:00.000
      to: moment(endDate).utc().endOf('day'), // 23:59:58.999
      report: ['Diff BRV', 'Diff JV', 'BPV'],
      status: ['pending']
    };

    dispatch({
      type: FETCH_VOUCHER_LIST.REQUEST,
      payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success("Difference and BPV Vouchers Fetched Successfully")
          }
        }
      },
    });
  };


  //VIEW PDF PERVIEW
  const viewVoucherPreview = (reportId, type, reportType) => {
    setLoadingReportId(reportId); // Set loading state
    setReportTypePreview(reportType);
    setVoucherTypePreview(type);

    const payload = { reportId };

    dispatch({
      type: VIEW_VOUCHER.REQUEST,
      payload,
      cb: (res) => {
        setLoadingReportId(null); // Reset loading state
        if (res && _.get(res, 'status') === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          setShowPdfModal(true);
          setpdfPreviewId(reportId)
        }
      },
    });
  };



  //SEND STATUS
  const handleVoucherStatus = (status) => {
    const numericMatchAmount = Number(matchAmount);
    if (isNaN(numericMatchAmount) || numericMatchAmount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    const payload = {
      reportId: pdfPreviewId,
      status: status, // "Approved" or "Rejected"
      approvedBy: profileData?.name,
      matchAmount: numericMatchAmount, // Send match amount in payload
      reportType: reportTypePreview || '',
      voucherType: voucherTypePreview || ''
    };
    dispatch({
      type: SUBMIT_VOUCHER_STATUS.REQUEST,
      payload,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          if (status === 'rejected') {
            toast.success("Voucher Rejected Successfully");
            setShowPdfModal(false);
            fetchAllVoucherList({});
            return
          }
          fileDownload(res.data, `voucher_status_${status}.pdf`);
          toast.success("Voucher Approved and Downloaded Successfully");
          setShowPdfModal(false);
          fetchAllVoucherList({});
        }
      },
    });
  };

  useEffect(() => {
    // Clear report list on page load
    dispatch({
      type: FETCH_VOUCHER_LIST.SUCCESS,
      payload: [],
    });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.difference')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col lg={'auto'}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={initialValues} // Yesterday as default start date
                          initialValueOfEndDate={initialValues} // Yesterday as default end date
                          minDate={moment().add(1, 'days').startOf('day')} // Prevent selection of today
                          closeOnSelect={true}
                          timeFormat={false} // Disable time selection
                        />
                      </div>
                    </Col>
                    <Col lg={'auto'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button type="submit" title='Show to be assign vouchers' className="apply--btn w-100" onClick={() => { fetchAllVoucherList({}) }}>
                        {t('Submit')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          <div className="data-table-wrapper mt-4">
            <h5>{t('Report List')}</h5>
            <table className="record-list-table" id="table-to-xls">
              <thead>
                <tr>
                  <th>{t('Type')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Date')}</th>
                  <th>{t('Transaction ID')}</th>
                  <th>{t('Status')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {reportList && reportList.length > 0 ? (
                  reportList.map((item) => (
                    <tr key={item.reportId}>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>
                        {moment(item.dateRange.from).format('YYYY-MM-DD')}
                      </td>
                      <td>{item.transactionId}</td>
                      <td>{item.status}</td>
                      {item.voucherDate && <td>{moment(item.voucherDate).format('DD-MM-YYYY')}</td>}
                      <td>
                        <button
                          className="job-report-button"
                          onClick={() => viewVoucherPreview(item._id, item.type, item.reportType)}
                          disabled={loadingReportId === item._id} // Disable button while loading
                        >
                          {loadingReportId === item._id ? t('Loading...') : t('View')}
                        </button>
                      </td>
                      {(!item.voucherNumber || !item.voucherDate) && (
                        <td style={{ display: 'none' }}>
                          <button
                            className="job-report-button responsive-button"
                            onClick={() => handleAssignVoucherClick(item.reportId, item.voucherNumber ? false : true, item.voucherDate ? false : true)}
                          >
                            {t(item.voucherNumber ? 'Assign Voucher Date' : item.voucherDate ? 'Assign Voucher Number' : 'Assign Voucher Number and Date')}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {t('All BPV & Difference Vouchers will be here')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal size={'auto'} show={showModal} onHide={handleModalClose} centered className="job-modal">
            <Modal.Header>
              <div className='job-modal-header'>
                <Modal.Title>{t('View Voucher Details')}</Modal.Title>
                <button onClick={handleModalClose}>
                  <MdClose />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className='job-modal-body'>
              {isVoucher && <input
                type="text"
                className="form-control mt-2"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                placeholder={t('Enter Voucher Number')}
              />}
              {isDate && <CustomDatePickerInput
                placeholder={t('offerForm.voucherDate')}
                label={t('offerForm.voucherDate')}
                name="voucherDate"
                value={voucherDate}
                onChange={(date) => setVoucherDate(date)}
              />}
            </Modal.Body>
            <Modal.Footer className='job-modal-footer'>
              <Button onClick={handleVoucherSubmit} className='job-report-button'>
                {t('Submit')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showPdfModal} className="job-modal" onHide={() => setShowPdfModal(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{t('PDF Preview')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='pdfPreview' style={{ overflow: 'hidden', display: 'flex' }}>
                {pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    height="700px"
                    style={{ border: 'none', transform: 'translateY(-70px)' }}
                    title="PDF Report Preview"
                  ></iframe>
                ) : (
                  <p>{t('Loading PDF...')}</p>
                )}

                <div className='approvalBlock' style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                  <TextInput
                    isRequired
                    label={t('Match Amount')}
                    placeholder={t('Enter Amount')}
                    name="consumerNumber"
                    value={matchAmount}
                    onChange={(e) => setMatchAmount(e.target.value)}
                  />
                  <button
                    className="job-report-button responsive-button"
                    style={{ margin: '10px 0px' }}
                    onClick={() => handleVoucherStatus('approved')}
                  >
                    {t('Approve')}
                  </button>
                  <button
                    className="job-report-button responsive-button"
                    onClick={() => handleVoucherStatus('rejected')}
                  >
                    {t('Reject')}
                  </button>

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>



          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default Difference;